<template>
  <div>
    <b-form-group
      label="Nombre"
      label-for="input-title"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-input
        id="input-title"
        class="mt-1"
        v-model="$v.instrument.title.$model"
        :state="validateState('title')"
        aria-describedby="input-title-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-title-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <!-- Puntaje -->
    <b-form-group
      label="Descripción"
      label-cols="0"
      label-cols-md="3"
      class="p-0 m-0 input-ml"
    >
      <div
        v-b-tooltip.v-secondary="'Editar Descripción'"
        class="instrument-description rich-text-content mr-0 mt-1"
        v-html="$v.instrument.description.$model"
        @click="
          $bvModal.show(`edit-instrument-description-modal-${instrument.id}`)
        "
      ></div>
      <b-modal
        :id="`edit-instrument-description-modal-${instrument.id}`"
        title="Editar Descripción del Instrumento"
        size="lg"
        hide-footer
      >
        <NewRichTextEditor
          :Object="instrument"
          :Text="instrument.description"
          @save="patchInstrument"
          @close="
            $bvModal.hide(`edit-instrument-description-modal-${instrument.id}`)
          "
        ></NewRichTextEditor>
      </b-modal>
    </b-form-group>
    <b-form-group
      label="Puntaje"
      label-for="input-score"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-input
        id="input-score"
        type="number"
        class="mt-1"
        v-model="$v.instrument.score.$model"
        :state="validateState('score') && isValid"
        aria-describedby="input-score-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-score-feedback" v-if="!isValid">
        El puntaje del instrumento no coincide con los puntos de las
        preguntas."{{ maxScoreValidator }}"</b-form-invalid-feedback
      >
      <b-form-invalid-feedback id="input-score-feedback" v-else>
        Debe ingresar un puntaje mayor a 0.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Tipo"
      label-for="select-type"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
      v-if="!TestForm"
    >
      <b-form-select
        id="select-type"
        value-field="id"
        text-field="value"
        class="mt-1"
        v-model="$v.instrument.instrument_type.$model"
        :state="validateState('instrument_type')"
        aria-describedby="select-type-feedback"
        :options="instrument_types"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback id="select-type-feedback"
        >Debe seleccionar un tipo de instrumento.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Sub-tipo"
      label-for="select-sub-type"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0 border-bottom"
    >
      <b-form-select
        id="select-sub-type"
        type="number"
        class="mt-1"
        v-model="$v.instrument.sub_type.$model"
        aria-describedby="select-sub-type-feedback"
        value-field="id"
        text-field="value"
        :options="sub_types"
        size="sm"
      >
        <template #first>
          <b-form-select-option :value="null"
            >No Aplica</b-form-select-option
          ></template
        >
      </b-form-select>
      <b-form-invalid-feedback id="select-sub-type-feedback"
        >Este campo es opcional.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Evidencias de Desempeño"
      label-for="select-procedure-evidence"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 mt-1 p-0 border-bottom"
    >
      <b-form-select
        id="select-procedure-evidence"
        v-model="instrument.procedure_evidences"
        aria-describedby="select-procedure-evidence-feedback"
        value-field="id"
        text-field="name"
        :options="procedure_evidences"
        size="sm"
        multiple
        :select-size="4"
        class="mb-1"
      >
      </b-form-select>
      <b-form-invalid-feedback id="select-sub-type-feedback"
        >Debe seleccionar al menos una evidencia de
        desempeño.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Fecha de Inicio"
      label-for="input-start-date"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 mt-1 p-0"
    >
      <b-form-datepicker
        id="input-start-date"
        v-model="start_date"
        :state="validateState('start_date')"
        placeholder="Definir fecha de inicio"
        aria-describedby="input-start-date-feedback"
        size="sm"
        class="mt-1"
      ></b-form-datepicker>
      <b-form-invalid-feedback id="input-start-date-feedback"
        >Debe ingresar una fecha de inicio válida.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Hora de Inicio"
      label-for="input-start_time"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0 border-bottom"
    >
      <b-form-timepicker
        id="input-start_time"
        v-model="start_time"
        class="mt-1 mb-2"
        :state="validateStartTime()"
        placeholder="Definir hora de inicio"
        aria-describedby="input-start_time-feedback"
        size="sm"
      ></b-form-timepicker>
      <b-form-invalid-feedback id="input-start_time-feedback"
        >Debe ingresar la hora de inicio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Fecha de Entrega"
      label-for="input-due-date"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 mt-1 p-0"
    >
      <b-form-datepicker
        id="input-due-date"
        v-model="due_date"
        :state="validateState('due_date')"
        placeholder="Definir fecha de entrega"
        aria-describedby="input-due-date-feedback"
        size="sm"
        class="mt-1"
        @input="setPublishDate()"
      ></b-form-datepicker>
      <b-form-invalid-feedback id="input-due-date-feedback"
        >Debe ingresar una fecha de entrega válida.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Hora de Entrega"
      label-for="input-due-time"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 mt-1 p-0 border-bottom"
    >
      <b-form-timepicker
        id="input-due-time"
        v-model="due_time"
        :state="validateDueTime()"
        placeholder="Definir hora de entrega"
        aria-describedby="input-due-time-feedback"
        required
        size="sm"
      ></b-form-timepicker>
      <b-form-invalid-feedback id="input-due-time-feedback"
        >Debe ingresar la hora de término.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Fecha de publicación"
      label-for="input-publish-date"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 mt-1 p-0"
    >
      <b-form-datepicker
        id="input-publish-date"
        v-model="instrument.publish_date"
        placeholder="Definir fecha de publicación"
        aria-describedby="input-publish-date-feedback"
        size="sm"
        class="mt-1"
        @input="publishDateChanged"
      ></b-form-datepicker>
      <b-form-invalid-feedback id="input-publish-date-feedback"
        >Este campo no es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      v-if="!TestForm"
      label-for="input-allow-access-instrument"
      class="m-0 p-0"
    >
      <!-- label-cols="0"
      label-cols-sm="3" -->
      <!-- :label="
        instrument.allow_access_student
          ? 'Mostrar Instrumento'
          : 'No mostrar Instrumento'
      " -->
      <div class="d-flex mt-2">
        <span style="margin-left: 0; margin-rigth: auto">{{
          instrument.allow_access_student
            ? "Mostrar Instrumento"
            : "No mostrar Instrumento"
        }}</span>
        <b-form-checkbox
          style="margin-left: auto; margin-rigth: 0"
          class="check-allow-access-instrument"
          v-model="instrument.allow_access_student"
          switch
        ></b-form-checkbox>
      </div>
    </b-form-group>
    <b-form-group
      v-if="TestForm"
      label-for="input-retake-instrument"
      class="m-0 p-0"
    >
      <!-- :label="
        instrument.retake_instrument
          ? 'Se permite retomar el instrumento'
          : 'No se puede retomar el instrumento'
      " -->
      <!-- label-cols="0"
      label-cols-sm="3" -->
      <div class="d-flex mt-3">
        <!-- <span v-if="instrument.retake_instrument"
          >Se permite retomar el instrumento</span
        >
        <span v-else>No se puede retomar el instrumento</span> -->
        <span style="margin-left: 0; margin-rigth: auto">
          Permitir retomar instrumento:
          <!-- {{
            instrument.retake_instrument
              ? "Se permite retomar el instrumento:"
              : "No se puede retomar el instrumento:"
          }} -->
        </span>
        <b-form-checkbox
          style="margin-left: auto; margin-rigth: 0"
          class="check-retake-instrument"
          v-model="instrument.retake_instrument"
          switch
        ></b-form-checkbox>
        <!-- @input="retakeInstrument()" -->
      </div>
    </b-form-group>
    <b-form-group
      v-if="instrument.retake_instrument"
      label="Intentos para retomar instrumento"
      label-for="input-retake-instrument-attempts"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <div class="d-flex mt-2">
        <b-form-input
          type="number"
          id="input-retake-instrument-attempts"
          class="retake-instrument-attempts"
          size="sm"
          v-model="instrument.retake_instrument_attempts"
        ></b-form-input>
      </div>
    </b-form-group>

    <b-form-group label-for="input-allow-access-instrument" class="m-0 p-0">
      <div class="d-flex mt-2">
        <span style="margin-left: 0; margin-rigth: auto">{{
          instrument.show_attached_before_start_date
            ? "Se mostrará los archivos adjuntos antes de la fecha de inicio del Instrumento:"
            : "Se mostrará los archivos adjuntos solo cuando inicie el Instrumento:"
        }}</span>
        <b-form-checkbox
          style="margin-left: auto; margin-rigth: 0"
          class="check-allow-access-instrument"
          v-model="instrument.show_attached_before_start_date"
          switch
        ></b-form-checkbox>
      </div>
    </b-form-group>
    <b-form-group
      label="Archivos"
      label-for="file-attachment"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <template v-if="instrument.attachments.length > 0">
        <FileContainer
          :Files="instrument.attachments"
          :allows_crud="allows_crud"
          @deleteFile="slotDeleteFile"
        ></FileContainer>
      </template>
      <b-button
        @click="$bvModal.show('modal-select-file')"
        size="sm"
        class="open-file-selector mt-1"
        >Seleccionar Archivo</b-button
      >
      <b-modal id="modal-select-file" title="Seleccionar Archivos" hide-footer>
        <FileSelector @created="slotCreatedFile"></FileSelector>
      </b-modal>
    </b-form-group>
    <div class="row my-1">
      <div
        class="col"
        v-if="allows_crud && show_delete_button"
        style="text-align: left"
      >
        <b-button class="mx-1" size="sm" variant="danger" @click="emitDelete"
          >Eliminar</b-button
        >
      </div>
      <div class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="emitSave">Guardar</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minValue } from "vuelidate/lib/validators";
import { generateUniqueId } from "@/utils/utils";
// import moment from "moment";

export default {
  name: "InstrumentForm",
  mixins: [validationMixin],
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
    FileSelector: () => import("@/components/reusable/FileSelector"),
    FileContainer: () => import("@/components/reusable/FileContainer"),
  },
  props: {
    Instrument: {
      type: Object,
      required: true,
    },
    evaluation_id: {
      type: Number,
      required: true,
    },
    TestForm: {
      type: Boolean,
      required: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    allows_crud: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      instrument: {
        id: this.Instrument ? this.Instrument.id : generateUniqueId(),
        evaluation: this.Instrument
          ? this.Instrument.evaluation
          : this.evaluation_id,
        title: this.Instrument ? this.Instrument.title : "",
        description: this.Instrument ? this.Instrument.description : "",
        score: this.Instrument ? this.Instrument.score : null,
        sub_type: this.Instrument ? this.Instrument.sub_type : null,
        procedure_evidences: this.Instrument
          ? this.Instrument.procedure_evidences
          : [],
        instrument_type: this.Instrument
          ? this.Instrument.instrument_type
          : null,
        start_date: this.Instrument ? this.Instrument.start_date : null,
        due_date: this.Instrument ? this.Instrument.due_date : null,
        publish: true,
        publish_date: this.Instrument ? this.Instrument.publish_date : null,
        retake_instrument: this.Instrument
          ? this.Instrument.retake_instrument
          : false,
        retake_instrument_attempts: this.Instrument
          ? this.Instrument.retake_instrument_attempts
          : null,
        allow_access_student: this.Instrument
          ? this.Instrument.allow_access_student
          : false,
        show_attached_before_start_date: this.Instrument
          ? this.Instrument.show_attached_before_start_date
          : false,
        attachments: this.Instrument ? this.Instrument.attachments : [],
      },
      start_date: null,
      start_time: "09:00:00",
      due_date: null,
      due_time: "09:00:00",
      publish_date: null,
      sub_types: null,
      procedure_evidences: [],
      instrument_types: null,
      start_time_empty: false,
      attachment: [],
    };
  },
  validations() {
    return {
      instrument: {
        title: { required },
        description: {},
        score: { required, minValue: minValue(1) },
        sub_type: {},
        procedure_evidences: {},
        instrument_type: {
          validateType() {
            if (!this.TestForm && this.instrument.instrument_type != null)
              return true;
            else if (this.TestForm) {
              return true;
            } else {
              return false;
            }
          },
        },
        start_date: {
          required,
          dateValidation() {
            return this.validStartDateFormat;
          },
        },
        due_date: {
          required,
          dateValidation() {
            return this.validDueDateFormat;
          },
        },
      },
      start_time: {
        required,
        dateValidation() {
          return this.validStartTimeFormat;
        },
      },
      due_time: {
        required,
        dateValidation() {
          return this.validDueTimeFormat;
        },
      },
      validationGroup: ["instrument", "start_time", "due_time"],
    };
  },
  computed: {
    ...mapGetters({
      questions: names.QUESTIONS,
    }),
    isValid() {
      if (!isNaN(this.instrument.id) && this.TestForm) {
        if (this.maxScoreValidator == this.instrument.score) return true;
        else return false;
      } else return true;
    },
    validStartDateFormat() {
      if (this.start_date == "Fecha inválida") return false;
      return true;
    },
    validStartTimeFormat() {
      if (this.start_time == "Fecha inválida") return false;
      return true;
    },
    validDueDateFormat() {
      if (this.due_date == "Fecha inválida") return false;
      return true;
    },
    validDueTimeFormat() {
      if (this.due_time == "Fecha inválida") return false;
      return true;
    },
    maxScoreValidator() {
      if (!isNaN(this.instrument.id) && this.TestForm) {
        let questions = this.questions.filter(
          (x) => x.question_test == this.instrument.id
        );
        let MaxCalculatedScore = 0;
        questions.forEach((element) => {
          MaxCalculatedScore += element.score;
        });
        return MaxCalculatedScore;
      }
      return 0;
    },
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.instrument[key];
      return $dirty ? !$error : null;
    },
    validateStartTime() {
      const { $dirty, $error } = this.$v.start_time;
      return $dirty ? !$error : null;
    },
    validateDueTime() {
      const { $dirty, $error } = this.$v.due_time;
      return $dirty ? !$error : null;
    },
    emitSave() {
      this.$v.validationGroup.$touch();
      if (this.$v.validationGroup.$anyError) {
        return;
      }
      // this.$v.instrument.$touch();
      // if (this.$v.instrument.$anyError) {
      //   return;
      // }
      if (this.instrument.start_date > this.instrument.due_date) {
        return this.$swal({
          text: "La fecha de inicio no puede ocurrir después que la fecha de entrega.",
          type: "warning",
        });
      }
      if (
        this.$moment(this.instrument.publish_date).format(
          "YYYY-MM-DD H:MM:SS"
        ) < this.instrument.start_date
      ) {
        return this.$swal({
          text: "La fecha de publicación no puede ocurrir antes que la fecha de inicio.",
          type: "warning",
        });
      }
      if (
        this.$moment(this.instrument.publish_date).format(
          "YYYY-MM-DD H:MM:SS"
        ) < this.instrument.due_date
      ) {
        return this.$swal({
          text: "La fecha de publicación no puede ocurrir antes que la fecha de entrega.",
          type: "warning",
        });
      }
      this.$emit("created", this.instrument);
    },
    emitDelete() {
      this.$emit("delete", this.instrument);
    },
    slotCreatedFile(file) {
      if (file) {
        this.instrument.attachments.push(file.id);
        this.$emit("update_file", file);
      }
      this.$bvModal.hide("modal-select-file");
    },
    slotDeleteFile(file_id) {
      const index = this.instrument.attachments.indexOf(file_id);
      if (index != -1) this.instrument.attachments.splice(index, 1);
      const index2 = this.Instrument.attachments.indexOf(file_id);
      if (index2 != -1) this.Instrument.attachments.splice(index, 1);
    },
    setStartDate() {
      if (!this.start_date || !this.start_time) return;
      let date = this.start_date + " " + this.start_time;
      this.instrument.start_date = date;
    },
    setEndDate() {
      if (!this.due_date || !this.due_time) return;
      let date = this.due_date + " " + this.due_time;
      this.instrument.due_date = date;
    },
    setPublishDate() {
      if (!this.due_date) return;
      var result = new Date(this.due_date);
      result.setDate(result.getDate() + 8);
      this.instrument.publish_date =
        this.$moment(result).format("YYYY-MM-DD H:MM:SS");
    },
    patchInstrument(object, text) {
      this.instrument.description = text;
    },
    fetchProcedureEvidences() {
      this.$restful.Get(`/mesh/procedure-evidence/`).then((response) => {
        this.procedure_evidences = response;
      });
    },
    publishDateChanged() {
      this.instrument.publish_date = this.$moment(
        this.instrument.publish_date
      ).format("YYYY-MM-DDTHH:MM:SSZ");
    },
    retakeInstrument() {
      this.$emit("instrumentStateChanged", this.instrument.retake_instrument);
    },
  },
  watch: {
    start_date() {
      this.setStartDate();
    },
    start_time() {
      this.setStartDate();
    },
    due_date() {
      this.setEndDate();
    },
    due_time() {
      this.setEndDate();
    },
  },
  created() {
    this.fetchProcedureEvidences();
    this.$restful.Get(`/evaluation/sub-type/`).then((response) => {
      this.sub_types = response;
    });
    this.$restful.Get(`/evaluation/instrument-type/`).then((response) => {
      this.instrument_types = response;
    });
    try {
      const start_date = this.$moment(this.Instrument.start_date);
      this.start_date = start_date.format("YYYY-MM-DD");
      this.start_time = start_date.format("HH:mm:ss");
    } catch {
      this.start_date = this.$moment().format("YYYY-MM-DD");
      this.start_time = this.$moment().format("HH:mm:ss");
    }
    try {
      const due_date = this.$moment(this.Instrument.due_date);
      this.due_date = due_date.format("YYYY-MM-DD");
      this.due_time = due_date.format("HH:mm:ss");
    } catch {
      this.due_date = this.$moment().format("YYYY-MM-DD");
      this.due_time = this.$moment().add(2, "hours").format("HH:mm:ss");
    }
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.instrument-description {
  width: calc(100%);
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  height: auto;
  padding: 0.25rem 1.2rem;
  line-height: 1.5;
}
.instrument-description >>> p {
  margin-bottom: 0.7rem !important;
}
.custom-file-input:lang() ~ .custom-file-label::after {
  content: "Buscar" !important;
}
.open-file-selector {
  width: 100%;
}
.check-retake-instrument {
  margin-left: 0.4em;
  margin-top: 0.1em;
}
.input-ml {
  margin-left: 0.17rem !important;
}
</style>